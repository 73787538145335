import React, { useState, useEffect } from 'react';
import './Avaliacao.css';
import Select from 'react-select';
import { fetchGroupersData } from '../../services/api/fetchGrouper';
import { sendEvaluationData } from '../../services/api/fetchGrouper';
import { fetchGroupers } from '../../services/api/fetchGrouper';
import { fetchClientSubjects } from '../../services/api/fetchGrouper';

function Avaliacao({ clientId, clippingId, onClose, onAddFilter, loadExistingData }) {
  const [groupers, setGroupers] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getGroupers = async () => {
    try {
      const response = await fetchGroupers(clientId);
      const sortedGroupers = response.sort((a, b) => a.id - b.id);
      setGroupers(sortedGroupers);
  
      if (loadExistingData) {
        const evaluationData = await fetchGroupersData(clippingId);
        const initialValues = {};
  
        evaluationData.forEach(item => {
          // Verifica se clippingId é um array
          if (Array.isArray(clippingId)) {
            // Se for array, usa includes
            if (clippingId.includes(item.clipping)) {
              initialValues[item.grouper_detail.id] = item.value;
            }
          } else {
            // Se for um valor único, compara diretamente
            if (item.clipping == clippingId) {
              initialValues[item.grouper_detail.id] = item.value;
            }
          }
        });
  
        setSelectedValues(initialValues);
      }

      const fetchedSubjects = await fetchClientSubjects(clientId);
      setSubjects(fetchedSubjects.subjects || []);
  
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar groupers:', error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getGroupers();
  }, [clientId, clippingId, loadExistingData]);
  
  

  const handleInputChange = (id, value) => {
    setSelectedValues({
      ...selectedValues,
      [id]: value
    });
  };

  const handleAddFilter = async () => {
    const filters = groupers.map(grouper => {
      const value = selectedValues[grouper.id];
      return value ? `${grouper.id}:${value.trim()}` : null;
    }).filter(Boolean);

    const clippingIdsArray = Array.isArray(clippingId) ? clippingId : [clippingId];

    const filterData = {
      client: clientId,
      clippings: clippingIdsArray,
      filters,
    };

    console.log('Dados enviados para a API:', filterData);

    try {
      const response = await sendEvaluationData(filterData);
      console.log('Resposta da API:', response);
      setMessage('Avaliação realizada com sucesso!');
      setError('');
    } catch (error) {
      console.error('Erro ao enviar dados para a API:', error);
      console.error('Detalhes do erro:', error.response);
      setMessage('');
      setError('Erro ao realizar a avaliação. Por favor, tente novamente.');
    }

    onAddFilter(filterData);
    onClose();
  };

  const handleNumberInputChange = (id, value) => {
    let numericValue = value.replace(/\D/g, '');
    if (numericValue) {
      numericValue = `R$ ${numericValue}`;
    }
    setSelectedValues({
      ...selectedValues,
      [id]: numericValue
    });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      padding: '10px',
      fontSize: '14px',
      borderColor: state.isFocused ? '#ccc' : '#ccc',
      borderRadius: '4px',
      backgroundColor: 'white',
      color: '#333',
      outline: 'none',
      boxShadow: state.isFocused ? '0 0 0 1px #ccc' : 'none',
      transition: 'border-color 0.3s, box-shadow 0.3s',
      '&:hover': {
        borderColor: '#ccc',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#eee' : '#fff',
      color: '#333',
      padding: '10px',
      '&:active': {
        backgroundColor: '#eee',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  if (loading) {
    return null;
  }

  const splitFiltersIntoColumns = () => {
    const numColumns = 2;
    const filtersPerColumn = Math.ceil(groupers.length / numColumns);
    const columns = [];

    for (let i = 0; i < numColumns; i++) {
      columns.push(groupers.slice(i * filtersPerColumn, (i + 1) * filtersPerColumn));
    }

    return columns;
  };

  const columns = splitFiltersIntoColumns();

  return (
    <section className="container_avaliacao">
      <button className="close-button_avaliacao" aria-label="Close" onClick={onClose}>x</button>
      <h1 className="title_avaliacao">Avaliação</h1>
      <hr className='hr'></hr>

      <div className="filters_container_avaliacao">
        {columns.map((column, index) => (
          <div key={index} className="filter-column">
            {column.map(grouper => (
              <div key={grouper.id} className={`date-picker-filters-avaliacao ${grouper.field_type === 'text' ? 'full-width' : ''}`}>
                <div className="date-picker-label-filters-avaliacao">{grouper.name}</div>
                {grouper.field_type === 'select' && grouper.values_list && (
                  <Select
                    styles={customStyles}
                    value={selectedValues[grouper.id] ? { value: selectedValues[grouper.id], label: selectedValues[grouper.id] } : null}
                    onChange={(selectedOption) => handleInputChange(grouper.id, selectedOption ? selectedOption.value : '')}
                    options={grouper.values_list.map(option => ({
                      value: option.value,
                      label: option.value
                    }))}
                    placeholder="Todas"
                  />
                )}
                {grouper.field_type === 'radio' && grouper.values_list && (
                  grouper.values_list.map((option, index) => (
                    <div key={index} className="feature_avaliacao">
                      <input
                        type="radio"
                        id={`${grouper.id}-${index}`}
                        name={`feature-${grouper.id}`}
                        value={option.value}
                        className="radio-item_avaliacao"
                        checked={selectedValues[grouper.id] === option.value}
                        onChange={(e) => handleInputChange(grouper.id, e.target.value)}
                      />
                      <label htmlFor={`${grouper.id}-${index}`} className="feature-label_avaliacao">
                        {option.value}
                      </label>
                    </div>
                  ))
                )}
                {grouper.field_type === 'number' && (
                  <input
                    type="text"
                    className="custom-select-tema"
                    placeholder="Digite a valoração"
                    value={selectedValues[grouper.id] || ''}
                    onChange={(e) => handleNumberInputChange(grouper.id, e.target.value)}
                  />
                )}
                {grouper.field_type === 'text' && grouper.name === 'Assunto' && (
                  <div style={{ position: 'relative', display: 'inline-block', width: '100%'}}>
                    <input
                      type="text"
                      className="custom-select-tema"
                      placeholder="Digite ou selecione um assunto"
                      value={selectedValues[grouper.id] || ''}
                      onChange={(e) => handleInputChange(grouper.id, e.target.value)}
                      onFocus={() => setDropdownOpen(true)}
                      onBlur={() => setTimeout(() => setDropdownOpen(false), 200)}
                    />
                    {dropdownOpen && (
                      <ul
                        style={{
                          position: 'absolute',
                          top: '100%',
                          left: 0,
                          right: 0,
                          minWidth: '168px',
                          maxHeight: '170px',
                          overflowY: 'auto',
                          backgroundColor: 'white',
                          border: '1px solid #ccc',
                          zIndex: 1000,
                          listStyle: 'none',
                          marginTop: -17,
                          padding: '5px 0',
                          borderRadius: '5px',
                        }}
                      >
                        {subjects
                          .filter((subject) =>
                            subject.toLowerCase().includes((selectedValues[grouper.id] || '').toLowerCase())
                          )
                          .map((subject, index) => (
                            <li
                              key={index}
                              style={{
                                padding: '5px 10px',
                                cursor: 'pointer',
                                backgroundColor:
                                  subject === selectedValues[grouper.id] ? '#121926' : 'white',
                                color: subject === selectedValues[grouper.id] ? 'white' : '#121926',
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = '#121926';
                                e.currentTarget.style.color = 'white';
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = subject === selectedValues[grouper.id] ? '#121926' : 'white';
                                e.currentTarget.style.color = subject === selectedValues[grouper.id] ? 'white' : 'black';
                              }}
                              onMouseDown={() => handleInputChange(grouper.id, subject)}
                            >
                              {subject}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                )}
                {grouper.field_type === 'text' && grouper.name !== 'Assunto' && (
                  <input
                    type="text"
                    className="custom-select-tema"
                    placeholder="Texto"
                    value={selectedValues[grouper.id] || ''}
                    onChange={(e) => handleInputChange(grouper.id, e.target.value)}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}

      <div className="buttons_container_avaliacao">
        <button className="block-rate_avaliacao" aria-label="Avaliar noticias em bloco" onClick={handleAddFilter}>
          Avaliar Noticias
        </button>
        <button className="cancel-button_avaliacao" aria-label="Cancelar" onClick={onClose}>
          Cancelar
        </button>
      </div>
    </section>
  );
}

export default Avaliacao;
