import React, { forwardRef, useImperativeHandle } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import DOMPurify from 'dompurify';
import htmlToPdfMake from 'html-to-pdfmake';
import { fetchClientData } from './api/fetchClient';
import { createCanvas, loadImage } from 'canvas';

if (pdfFonts.pdfMake && pdfFonts.pdfMake.vfs) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
} else {
  pdfMake.vfs = pdfFonts;
}

// Função para converter URLs de imagens para base64
const convertImageUrlToBase64 = async (imageUrl) => {
  try {
    const proxy = imageUrl.replace('amtex-nxt-media.s3.amazonaws.com', 'cliente.contentclipping.com.br');
    const response = await fetch(proxy);
    if (!response.ok) throw new Error('Network response was not ok');
    const imageBlob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        let base64Data = reader.result;
        if (base64Data.includes('image/webp')) {
          base64Data = await convertWebPToPNG(base64Data);
        }
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(imageBlob);
    });
  } catch (error) {
    console.error('Erro ao converter URL da imagem para base64:', error);
    return null; // Retorna null em caso de erro
  }
};

// Função para converter imagens webp para png
const convertWebPToPNG = async (webPDataUrl) => {
  try {
    const img = await loadImage(webPDataUrl);
    const canvas = createCanvas(img.width, img.height);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  } catch (error) {
    console.error('Erro ao converter WebP para PNG:', error);
    return webPDataUrl; // Retorna o original em caso de erro
  }
};

const getDataUrl = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = () => {
      reject(new Error('Failed to load image'));
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

const NewsPDFExporter = forwardRef(({ clientId }, ref) => {
  useImperativeHandle(ref, () => ({
    exportPDF: async (newsItems , userTitle = '', userSubtitle = '', sortOption, includeTitleAndSubtitle, includeSummary) => {
      // Função para adicionar a página de capa
      const addCoverPage = async (docContent, brandImage, clientData) => {
        const linkClient = process.env.REACT_APP_CLIENT_URL;

        const topImageUrl = 'https://cliente.contentclipping.com.br/media/black-blue-banner.png';
        const bottomImageUrl = 'https://cliente.contentclipping.com.br/media/black-blue-banner2.png';

        const topImageBase64 = await convertImageUrlToBase64(topImageUrl);
        const bottomImageBase64 = await convertImageUrlToBase64(bottomImageUrl);

        const companyName = clientData.company_name;
        const currentDate = getCurrentDate();

        const reportTitle = includeTitleAndSubtitle ? (userTitle || companyName) : ''; 
        const reportSubtitle = includeTitleAndSubtitle ? (userSubtitle || currentDate) : '';

        const reportCoverImage = clientData.report_cover_image
        ? clientData.report_cover_image.replace(process.env.REACT_APP_AWS_URL, `${linkClient}`)
        : null;

        if (reportCoverImage) {
          const reportCoverImageBase64 = await convertImageUrlToBase64(reportCoverImage);
      
          docContent.push(
            {
              image: reportCoverImageBase64,
              width: 635, // Largura total da página
              height: 842, // Altura total da página
              alignment: 'center',
              absolutePosition: { x: 0, y: 0 }
            },
            {
              stack: [
                { text: reportTitle, style: 'header', alignment: 'center', absolutePosition: { x: 40, y: 400 } },
                { text: reportSubtitle, style: 'subheader', alignment: 'center', margin: [0, 390, 0, 0] },
              ],
            },
            { text: '', pageBreak: 'after' }
          );
        } else {
          if (topImageBase64) {
            docContent.push({ image: topImageBase64, width: 635, alignment: 'center', absolutePosition: { x: 0, y: 0 } });
          }
      
          docContent.push(
            {
              stack: [
                { text: reportTitle, style: 'header', alignment: 'center' },
                { text: reportSubtitle, style: 'subheader', alignment: 'center', margin: [0, 5, 0, 5] },
                brandImage ? { image: brandImage, width: 120, absolutePosition: { x: 10, y: 20 } } : {},
              ],
              absolutePosition: { x: 0, y: 150 }
            }
          );
      
          if (bottomImageBase64) {
            docContent.push({ image: bottomImageBase64, width: 550, alignment: 'start', absolutePosition: { x: 0, y: 772 } });
          }
      
          docContent.push({ text: '', pageBreak: 'after' });
        }
      };

      const addTableOfContentsPage = (docContent) => {
        docContent.push(
          { toc: { title: { text: 'SUMÁRIO', style: 'tocTitle' }, numberStyle: 'tocNumber', textStyle: 'tocItemTitle' } },
          { text: '', pageBreak: 'after' }
        );
      };

      const addNewsItemPage = async (docContent, item, brandImage, isLastItem, clientData) => {
        let contentToRender = '';
        if (item.source_detail.source_type === 'tv' || item.source_detail.source_type === 'radio') {
          const link = `https://cliente.contentclipping.com.br/visualizarNoticiaNewsletter/${item.id}`;
          contentToRender = `${item.resume}<br/><br/><a href="${link}" target="_blank">Visualização da notícia</a>`;
        } else if (item.source_detail.source_type === 'site' || item.source_detail.source_type === 'blog') {
          contentToRender = item.content_text;
        }

        // Adiciona conteúdo formatado para tipos diferentes de 'impresso'
        if (item.source_detail.source_type !== 'impresso') {
          const cleanHtmlContent = await removeDuplicateIds(DOMPurify.sanitize(contentToRender, { FORBID_TAGS: ['iframe', 'video', 'script', 'style', 'svg', 'noscript'], FORBID_ATTR: ['style'] }));

          const pdfContent = htmlToPdfMake(cleanHtmlContent);

          // Garantir que pdfContent seja um array
          const contentArray = Array.isArray(pdfContent) ? pdfContent : [pdfContent];

          const reportCoverColor = clientData.report_cover_color; 

          docContent.push(
            // Adiciona o gradiente atrás da imagem da marca
            reportCoverColor
            ? {
                canvas: [
                  {
                    type: 'rect',
                    x: 0,
                    y: 0,
                    w: 600,
                    h: 40,
                    color: reportCoverColor // Usa a cor sólida
                  }
                ],
                absolutePosition: { x: 0, y: 0 }
              }
            : {
                canvas: [
                  {
                    type: 'rect',
                    x: 0,
                    y: 0,
                    w: 600,
                    h: 40,
                    linearGradient: ['#4f1a72', 'white', '#639e9a'] // Usa o gradiente padrão
                  }
                ],
                absolutePosition: { x: 0, y: 0 }
              }
          );

          // if (brandImage) {
          //   docContent.push({ image: brandImage, maxWidth: 100, maxHeight: 40, absolutePosition: { x: 260, y: 5 } });
          // }

          docContent.push(
            { text: `${item.source_detail.name} | ${item.publish_date} | ${item.source_detail.source_type} | ${item.categories.map(cat => cat.name).join(', ')}`.toUpperCase(), style: 'subtitle', alignment: 'center', fontSize: 8 },
            { text: item.title, style: 'title', alignment: 'center', fontSize: 12, bold: true, tocItem: true },
            {
              canvas: [
                { type: 'line', x1: 0, y1: 10, x2: 595 - 2 * 40, y2: 10, lineWidth: 1, lineColor: '#d3d3d3' }
              ],
              margin: [0, 10]
            },
            ...contentArray.map((content) => ({ ...content, alignment: 'justify' }))
          );

          if (!isLastItem) {
            docContent.push({ text: '', pageBreak: 'after' });
          }
        }

        // Adiciona apenas as imagens para o tipo 'impresso'
        if (item.source_detail.source_type === 'impresso' && item.thumbnails && item.thumbnails.length > 0) {
          const reportCoverColor = clientData.report_cover_color;

          docContent.push(
            // Adiciona o gradiente atrás da imagem da marca
            reportCoverColor
              ? {
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 600,
                      h: 40,
                      color: reportCoverColor // Usa a cor sólida
                    }
                  ],
                  absolutePosition: { x: 0, y: 0 }
                }
              : {
                  canvas: [
                    {
                      type: 'rect',
                      x: 0,
                      y: 0,
                      w: 600,
                      h: 40,
                      linearGradient: ['#4f1a72', 'white', '#639e9a'] // Usa o gradiente padrão
                    }
                  ],
                  absolutePosition: { x: 0, y: 0 }
                }
          );

          // if (brandImage) {
          //   docContent.push({ image: brandImage, maxWidth: 100, maxHeight: 40, absolutePosition: { x: 260, y: 10 } });
          // }

          docContent.push(
            { text: `${item.source_detail.name} | ${item.publish_date} | ${item.source_detail.source_type } | ${item.categories.map(cat => cat.name).join(', ')} | Página: ${item.order}`.toUpperCase(), style: 'subtitle', alignment: 'center', fontSize: 8 },
            { text: item.title, style: 'title', alignment: 'center', fontSize: 12, bold: true, tocItem: true },
            {
              canvas: [
                { type: 'line', x1: 0, y1: 10, x2: 595 - 2 * 40, y2: 10, lineWidth: 1, lineColor: '#d3d3d3' }
              ],
              margin: [0, 10]
            }
          );

          for (let i = 0; i < item.thumbnails.length; i++) {
            const thumbnailUrl = item.thumbnails[i];
            const thumbnailBase64 = await convertImageUrlToBase64(thumbnailUrl);
            if (thumbnailBase64) {
              docContent.push(
                { image: thumbnailBase64, width: 510, alignment: 'center', maxWidth: 510, maxHeight: 580 }
              );

              if (i < item.thumbnails.length - 1 || !isLastItem) {
                docContent.push({ text: '', pageBreak: 'after' });
              }
            }
          }
        }
      };

      try {
        if (!clientId) {
          throw new Error("clientId is not defined");
        }

        const clientData = await fetchClientData(clientId);
        const brandImageUrl = clientData.brand.replace('amtex-nxt-media.s3.us-east-2.amazonaws.com', 'cliente.contentclipping.com.br');
        const brandBase64 = await convertImageUrlToBase64(brandImageUrl);

        let sortedNewsItems = [...newsItems]; 

        const parseDateTime = (dateString) => {
          const [datePart, timePart] = dateString.split(' ');
          const [day, month, year] = datePart.split('/').map(Number);
          const [hours, minutes] = timePart ? timePart.split(':').map(Number) : [0, 0];
          return new Date(year, month - 1, day, hours, minutes);
        };

        if (sortOption === 'dataRecente') {
          sortedNewsItems.sort((a, b) => parseDateTime(b.publish_date) - parseDateTime(a.publish_date));
        } else if (sortOption === 'dataAntiga') {
          sortedNewsItems.sort((a, b) => parseDateTime(a.publish_date) - parseDateTime(b.publish_date));
        } else if (sortOption === 'veiculo') {
          sortedNewsItems.sort((a, b) => {
            const orderA = a.source_detail.order || 0;
            const orderB = b.source_detail.order || 0;
        
            if (orderA === orderB) {
              return a.source_detail.name.localeCompare(b.source_detail.name);
            }
        
            if (orderA === 0) return 1; 
            if (orderB === 0) return -1;
        
            return orderA - orderB;
          });
        } else if (sortOption === 'selecionada') {
            sortedNewsItems = [...newsItems];
        }

        const docContent = [];

        await addCoverPage(docContent, brandBase64, clientData);

        if (includeSummary) {
          addTableOfContentsPage(docContent);
        }

        for (let i = 0; i < sortedNewsItems.length; i++) {
          const item = sortedNewsItems[i];
          const isLastItem = i === sortedNewsItems.length - 1;
          await addNewsItemPage(docContent, item, brandBase64, isLastItem, clientData);
        }

        const docDefinition = {
          content: docContent,
          styles: {
            header: {
              fontSize: 14,
              bold: true,
              color: 'gray'
            },
            subheader: {
              fontSize: 10,
              color: 'gray'
            },
            title: {
              fontSize: 15,
              bold: true,
              color: 'gray'
            },
            subtitle: {
              fontSize: 12,
              margin: [0, 20],
              color: 'gray'
            },
            content: {
              fontSize: 12,
              margin: [0, 10]
            },
            tocTitle: {
              fontSize: 16,
              bold: true,
              alignment: 'center',
              margin: [0, 10],
              color: 'gray'
            },
            tocItemTitle: {
              fontSize: 8.5,
              margin: [0, 10]
            },
            tocNumber: {
              fontSize: 8.5,
              margin: [0, 10]
            }
          },
          footer: (currentPage) => {
            return {
              text: currentPage.toString(),
              alignment: 'right',
              fontSize: 10,
              margin: [0, 10, 10, 10],
              color: 'gray'
            };
          }
        };

        pdfMake.createPdf(docDefinition).download(`Clipping-${getCurrentDate()}.pdf`);
      } catch (error) {
        console.error('Erro ao buscar e converter a imagem do brand:', error);
      }
    },
  }));

  // Função para obter a data atual formatada
  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
  };

  // Função para remover IDs duplicados e ajustar atributos de imagem
  const removeDuplicateIds = async (htmlContent) => {
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    const allElements = doc.body.getElementsByTagName('*');

    const imgs = Array.from(doc.body.querySelectorAll('img'));
    const imgsToRemove = [];
    
    for (const img of imgs) {
      let src = img.getAttribute('src');
      if (src) {
        if (src.includes('svg+xml')) {
          src = img.getAttribute('data-srcset') ? img.getAttribute('data-srcset').split('?')[0] : null;
        }
        if (src) {
          src = await getDataUrl(src.split('?')[0]);
          img.src = src;

          // Ajustar max-width e max-height
          img.style.maxWidth = '500px';
          img.style.maxHeight = '700px';

          let validation = src.split(';base64')[0];
          if (!validation.includes('png') && !validation.includes('jpg') && !validation.includes('jpeg')) {
            imgsToRemove.push(img);
          }
        } else {
          imgsToRemove.push(img);
        }
      } else {
        imgsToRemove.push(img);
      }
    }

    imgsToRemove.forEach(img => {
      img.parentNode.removeChild(img);
    });

    Array.from(allElements).forEach(async (element) => {
      if (element.id) {
        element.removeAttribute('id');
      }

      if (element.getAttribute('src')) {
        const src = element.getAttribute('src');
        if (src) {
          element.src = await getDataUrl(src.split('?')[0]);
        }
      }
    });

    return doc.body.innerHTML;
  };

  return (
    <div style={{ display: 'none' }}></div>
  );
});

export default NewsPDFExporter;
